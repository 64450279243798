.flavorLineup {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.flavorLineupImage {
  width: 40%;
}
.flavorLineupContent {
  width: 60%;
  padding-top: 0;
  padding-left: 20px;
}
